
  import Vue from "vue";
  import {ROUTES} from "@/router/routes";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";

  export default Vue.extend({
    name: "pwa-tab-bar",
    components: {
      PwaIcon,
      PwaPrice,
    },
    data() {
      return {
        routes: ROUTES,
      }
    },
    computed: {
      hasUnreadMessages(): boolean {
        return this.$store.getters.hasUnreadMessages;
      },
      hasPromotions(): boolean {
        return Boolean(process.env.VUE_APP_HAS_PROMOTIONS);
      },
      hasChallenges(): boolean {
        return Boolean(process.env.VUE_APP_HAS_CHALLENGES);
      },
    }
  });
